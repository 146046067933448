//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 94px;
$outerHeight: 94px;

$lineHeight: 2px;
$lineWidth: 24px;
$gap: 7px;
$radius: 0px;

$outerWidthM: 32px;
$outerHeightM: 32px;

$lineHeightM: 2px;
$lineWidthM: 20px;
$gapM: 5px;
$radiusM: 0px;

$topLineColour: #323232;
$topLineColourOpen: $teal-light;

$middleLineColour: #323232;
$middleLineColourOpen: transparent;

$bottomLineColour: #323232;
$bottomLineColourOpen: $teal-light;




//@media (max-width: $b2){
//
//	.main-wrapper{
//
//		section{
//			overflow: hidden;
//			max-width: 375px;
//		}
//	}
//}

html.menu-open {
	overflow: hidden;
}

@media (min-width: $b2 + 1){

	html:not(.menu-open) .burger:not(.open){

		&:hover,
		&:focus-visible{
			
			.line1{
				background-color: $teal!important;
				transition: $time;

				&:before,
				&:after{
					background-color: $teal!important;
					transition: $time;
				}

				&:before{
					transform: translateY(-#{$gap + $lineHeight + 2px});
				}

				&:after{
					transform: translateY(#{$gap + $lineHeight + 2px});
				}
			}
		}
	}

	html:not(.menu-open).over-dark .burger{
		background-color: rgba(white, .75);
	}

	html:not(.menu-open).over-hero .burger {
		
		.line1,
		.line1::before,
		.line1::after {
			background: #fff;
		}
	}
}

.burger{
	height: $outerHeight;
	width: $outerWidth;
	border-radius: 0;
	line-height: $outerHeight;
	padding: 0;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	z-index: 1;
	pointer-events: auto;
	position: relative;
	transition: $time;
	font-weight: 400;

	@media (min-width: $b2 + 1){
		position: fixed;
		top: 50vh;
		left: 0;
		transform: translateY(-50%);
	}

	@media (max-width: $b2){
		height: $outerHeightM;
		width: $outerWidthM;
		line-height: $outerHeightM;
		z-index: 3;

		.side-open &{
			z-index: 1000;

			.line1.line1.line1.line1{
				background-color: white;
				
				&:before,
				&:after{
					background-color: white;
				}
			}
		}

		.menu-light:not(.menu-open):not(.scrolled) &{

			.line1.line1.line1.line1{
				background-color: white;
				
				&:before,
				&:after{
					background-color: white;
				}
			}
		}
	}

	&:hover{
		cursor: pointer;
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (($outerHeight / 2) - ($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;

		@media (max-width: $b2){
			width: $lineWidthM;
			height: $lineHeightM;
			top: (($outerHeightM / 2) - ($lineHeightM / 2));
		}

		&:before,
		&:after{
			width: $lineWidth;
			max-width: $lineWidth;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0px;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;
			transition: $time;

			@media (max-width: $b2){
				width: $lineWidthM;
				height: $lineHeightM;
				border-radius: $radiusM;
			}
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;

			@media (max-width: $b2){
				transform: translateY(-#{$gapM + $lineHeightM});
			}
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;

			@media (max-width: $b2){
				transform: translateY(#{$gapM + $lineHeightM});
			}
		}
	}

	&.close{

		&:before{
			transition: color .1s .25s;
		}

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s, background-image .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(-#{$gapM + $lineHeightM});
					animation: beforeM .5s linear;
				}
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(#{$gapM + $lineHeightM});
					animation: afterM .5s linear;
				}
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}

			@keyframes beforeM {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gapM + $lineHeightM});}
			}

			@keyframes afterM {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gapM + $lineHeightM});}
			}
		}
	}

	&.open{

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;
			background-color: $middleLineColourOpen;
			background-image: none;

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					animation: before-openM .5s linear;
				}
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
				
				@media (max-width: $b2){
					animation: after-openM .5s linear;
				}
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}

			@keyframes before-openM {
				0% {transform: translateY(-#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-openM {
				0% {transform: translateY(#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}
