//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: $b2){
		z-index: 111;
		margin-bottom: -1px;
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b2){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b2 + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 222px;
	max-width: 222px;
	transition: 0s;
	overflow: hidden;
	margin-left: -50px;

	@media (max-width: $b2){
		width: 115px;
		margin-left: -26px;
	}

	.scrolled &{

		svg{
			
			#logoText{
				fill: $grey;

				*{
					fill: $grey;
				}
			}
		}
	}

	.side-open &{
		z-index: 1000;

		svg{
			
			#logoText{
				fill: $white;

				*{
					fill: $white;
				}
			}
		}
	}

	.menu-open.menu-open.menu-open &,
	.menu-light:not(.scrolled) &{

		svg{
			
			#logoText{
				fill: $white;

				*{
					fill: $white;
				}
			}
		}
	}

	svg,
	img{
		display: block;
		width: 100%;

		#logoText{
			fill: $grey;
			transition: $time;

			*{
				fill: $grey;
				transition: $time;
			}
		}
	}
}

.menu-top-outer{
	transition: $time;
	padding-top: 26.5px;
	padding-bottom: 26.5px;

	.scrolled &{
		background-color: white;
	}
	
	@media (max-width: $b2){
		padding-top: 14px;
		padding-bottom: 14px;
	}
	
	.container{

	}
 	
	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.right,
	.left{
		display: flex;
		align-items: center;
	}

	.left{
		justify-content: flex-start;
	}

	.right{
		justify-content: flex-end;

		.help-btn{
			position: fixed;
			top: 0;
			height: 100vh;
			background-color: $grey-lighter;
			width: 53px;
			max-width: 39px;
			transition: $time;
			display: flex;
			justify-content: center;
			align-items: center;
			right: 0;
			cursor: pointer;

			@media (max-width: $b2){
				position: static;
				width: 32px;
				height: 32px;
				border-radius: 32px;
				background-color: $blue-dark;
				margin-left: 12px;
				z-index: 3;

				.menu-open &{
					background-color: white;

					svg{
						fill: $teal;
		
						*{
							fill: $teal;
						}
					}
				}

				.menu-light:not(.menu-open):not(.scrolled) &{
					background-color: white;

					svg{
						fill: $teal;
		
						*{
							fill: $teal;
						}
					}
				}
			}

			svg{
				fill: $teal;
				transition: $time;
				width: 14px;

				@media (max-width: $b2){
					fill: $teal-light;
				}

				*{
					fill: $teal;
					transition: $time;

					@media (max-width: $b2){
						fill: $teal-light;
					}
				}
			}

			@media (min-width: $b2 + 1){
				
				&:hover,
				&:focus-visible{
					max-width: 53px;
					background-color: $teal;

					svg{
						fill: $teal-light;
		
						*{
							fill: $teal-light;
						}
					}
				}
			}
		}
	}
}

.menu-bottom-outer{
	
	.left-side{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: $blue-dark;
		padding-top: 150px;
		padding-bottom: 50px;
		transform: translateX(-100%);
		overflow: auto;
		transition: $time;

		@media (max-width: 1600px){
			padding-top: 102px;
			padding-bottom: 50px;
		}

		@media (max-width: $b2){
			padding-top: 102px;
			padding-bottom: 50px;
		}

		// .main-menu-con{
		// 	max-height: calc(100vh - 150px);

		// 	@media (max-width: 1600px){
		// 		max-height: calc(100vh - 102px);
		// 	}

		// 	@media (max-width: $b2){
		// 		max-height: calc(100vh - 102px);
		// 	}
		// }

		.menu-open &{
			transform: translateX(0%);
		}
	}

	.searchform{
		position: relative;

		@media (max-width: $b2){
			margin-bottom: 128px;
		}

		::-webkit-input-placeholder {
			@include font-size(20);
			color: rgba(white,.78);
			font-weight: 400;

			@media (max-width: $b2){
				@include font-size(14);
			}
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(20);
			color: rgba(white,.78);
			font-weight: 400;

			@media (max-width: $b2){
				@include font-size(14);
			}
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(20);
			color: rgba(white,.78);
			font-weight: 400;

			@media (max-width: $b2){
				@include font-size(14);
			}
		}
		
		:-ms-input-placeholder {
			@include font-size(20);
			color: rgba(white,.78);
			font-weight: 400;

			@media (max-width: $b2){
				@include font-size(14);
			}
		}

		input{
			@include font-size(20);
			color: white;
			font-weight: 400;
			background-color: #2B414F;
			border: none;
			padding: 22px 90px 22px 28px;
			margin: 0;

			@media (max-width: $b2){
				@include font-size(14);
				padding: 20px 80px 20px 16px;
			}
		}

		button{
			position: absolute;
			top: 0;
			right: 0;
			background-image: url(../images/search.svg);
			background-repeat: no-repeat;
			margin: 0;
			background-color: $blue-medium;
			border: none;
			width: 70px;
			height: 70px;
			padding: 0;
			min-width: 70px;
			background-position: 50% 50%;
			transition: background 0.5s ease;

			&:hover {
				background-color: $teal;
			}

			@media (max-width: $b2){
				width: 58.2px;
				height: 58.2px;
				min-width: 58.2px;
				background-size: 21px auto;
			}
		}
	}

	.right-side{
		position: absolute;
		top: 0;
		right: 0;
		width: calc(100% - 94px);
		height: 100vh;
		background-color: $blue-dark;
		padding-top: 169px;
		padding-bottom: 50px;
		transform: translateX(100%);
		transition: $time;
		z-index: 999;

		@media (max-width: 1600px){
			padding-top: 126px;
		}

		@media (max-width: $b2){
			width: 100%;
			padding-top: 126px;
			padding-bottom: 50px;
		}
		
		.side-open &{
			transform: translateX(0%);

			.help-close{
				transform: translateX(0px) translateY(-50%);
				opacity: 1;
				visibility: visible;
			}
		}

		.help-close{
			position: absolute;
			top: 50%;
			transform: translateX(22.5px) translateY(-50%);
			width: 45px;
			height: 45px;
			left: -22.5px;
			border-radius: 30px;
			background-color: $blue-dark;
			border: 1px solid $blue-medium;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			opacity: 0;
			visibility: hidden;

			@media (max-width: $b2){
				position: absolute;
				width: 32px;
				height: 32px;
				top: 14px;
				right: 20px;
				left: auto;
				transform: none!important;
				border: none;
				background-color: $teal;
			}

			svg{
				fill: $teal;
				transition: $time;
				width: 14px;

				@media (max-width: $b2){
					fill: $teal-light;
				}

				*{
					fill: $teal;
					transition: $time;

					@media (max-width: $b2){
						fill: $teal-light;
					}
				}
			}

			@media (min-width: $b2 + 1){
				
				&:hover,
				&:focus-visible{
					background-color: $blue-medium;
				}
			}
		}

		h3{
			width: 600px;
			margin-bottom: 1.16em;

			@media (min-width: $b2 + 1){
				max-width: calc(100% - 250px);
			}

			@media (max-width: 1600px){
				margin-bottom: .86em;
			}

			@media (max-width: $b3){
				margin-bottom: .86em;
			}
		}

		.container{
			overflow: auto;
			max-height: calc(100vh - 169px);

			@media (max-width: $b2){
				max-height: calc(100vh - 126px);
			}

			@media (min-width: 1462px + 1){
				margin-left: calc((100vw - #{$base-width})/2 - 94px - 15px);
			}

			@media (max-width: 1462px){
	
				@media (min-width: $b2 + 1){
					margin-left: 30px;
				}
			}
		}

		*{
			color: white;
		}

		.title-top{
			position: relative;
			margin-bottom: 3.2em;

			@media (max-width: 1600px){
				margin-bottom: 2.2em;
			}

			@media (max-width: $b2){
				margin-bottom: 4.4em;
			}

			.social-menu{
				position: absolute;
				right: 15px;
				top: 0px;

				@media (max-width: $b2){
					position: static;
					margin-top: 27px;
				}

				a{
					color: $blue-dark;

					&:hover,
					&:focus-visible{
						background-color: $teal;
					}

					*{
						color: $blue-dark;
					}
				}
			}
		}

		.col-lg-4{

			@media (min-width: $b1 + 1){
				padding-left: 123px;
			}

			@media (max-width: $b2){
				margin-bottom: 58px;

				&:last-of-type{
					margin-bottom: 128px;
				}
			}

			h6{
				@include font-size(20);
				font-weight: 400;
				margin-bottom: 1.4em;

				@media (max-width: $b2){
					@include font-size(18);
					@include line-height(18,28);
					margin-bottom: 1em;
				}
			}

			p{
				width: 280px;

				@media (max-width: $b2){
					width: 285px;
				}
			}

			.arrow-link{
				margin-top: 3.15em;

				@media (max-width: 1600px){
					margin-top: 2.15em;
				}

				@media (max-width: $b2){
					margin-top: -.4em;
				}

				&:hover,
				&:focus-visible{
					color: $teal;
				}
			}
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	width: 726px;
}

@media (min-width: $b2 + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: #95EAED;
		}
	}
}

ul.main-menu {
	margin: 0 0 69px;
	padding: 0;
	@include font-size(0);
	position: relative;

	@media (max-width: 1600px){
		margin: 0 0 39px;
	}

	@media (max-width: $b2){
		margin: 0 0 54px;
	}
	
	@media (min-width: $b2 + 1){
		
		&:hover,
		&:focus-visible {

			> li {
				border-bottom: 1px solid rgba($teal,.1);

				> a{
					color: rgba(white, .2);
				}

				& > .siteHeader__arrowButton {
					opacity: 0.2;
				}
			}
		}
	}


	> li{
		@include font-size(0);
		line-height: 1;
		position: static;
		text-align: left;
		width: 100%;
		margin-bottom: 6px;
		border-bottom: 1px solid rgba($teal,.7);

		@media (max-width: $b2){
			margin-bottom: -2px;
		}

		@media (min-width: $b2 + 1){
			
			&:last-of-type{

				> a{
					border-bottom: none;
				} 
			}
		}
		
		// &:last-of-type{
		// 	margin-right: 0;
		// }

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		@media (min-width: $b2 + 1){

			&:hover{

				> a{
					// color: $green;
					color: #95EAED;
				}
			}
		}

		&.menu-item-has-children {
			display: flex;
			justify-content: space-between;
			align-items: center;
			overflow: visible;

			@media (min-width: $b2 + 1) {

				&:hover .siteHeader__arrowButton {
					opacity: 1;
				}
			}
		}

		> a{
			@include font-size(48);
			@include line-height(48,54);
			display: inline-block;
			color: white;
			text-decoration: none!important;
			font-weight: 200;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: 19px 0;
			overflow: hidden;
			width: calc(100% - 40px);
			position: relative;
			z-index: 2;

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{
					color: #95EAED;
				}
			}

			@media (max-width: 1600px){
				@include font-size(32);
			}

			@media (max-width: $b2){
				@include font-size(28);
				@include line-height(28,39);
				padding: 21px 0;
			}
		}
	}
}

body.search{


	@media (min-width: $b2 + 1){
		
		#menu-item-266#menu-item-266#menu-item-266#menu-item-266#menu-item-266{

			> a{
				color: white;
			}
	
			&:hover,
			&:focus-visible{
				
				> a{
					color: #95EAED;
				}
			}
		}
	}
	
}

// New sub menu stuff
#mainMenu {
	width: 58.7%;

	.main-menu-con {
		position: relative;
		width: 100%;
	}

	.main-menu {
		position: unset;
		width: 100%;
	}

	.main-menu > li {
		position: unset;

		&.menu-item-has-children.js-showSub .sub-menu-wrapper {
			opacity: 1;
			pointer-events: all;
		}

		@media screen and (max-width: 991px) {

			&.menu-item-has-children.js-showSub .sub-menu-wrapper {
				transform: translateX(0);
				pointer-events: all;
			}
		}
	}

	.sub-menu-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 31.1%;
		opacity: 0;
		pointer-events: none;
		height: 100%;
		overflow: auto;
		padding: 19px 0 0;
		transition: opacity 0.25s ease;
	}

	.siteHeader__titleHolder {
		position: relative;
		padding-bottom: 40px;
		margin-bottom: 40px;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 77px;
			height: 2px;
			background: #04B7BD;
		}

		h3 {
			@include font-size(16);
			font-weight: 500;
			color: #fff;
			margin: 0;
			letter-spacing: 0.04em;
			text-transform: uppercase;
		}
	}

	.siteHeader__subBackBtn {
		display: none;
	}

	.sub-menu {

		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		li a {
			@include font-size(32);
			font-weight: 200;
			color: #fff;
			line-height: 1.2;
			text-decoration: none;

			&:hover {
				color: #95EAED;
			}
		}
	}

	.siteHeader__arrowButton {
		display: inline-block;
		background: transparent;
		border: none;
		padding: 19px 0;
		margin: 0;
		border-radius: 0;
		width: auto;
		height: auto;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		z-index: 1;
		transition: opacity 0.25s ease;

		svg {
			width: 20px;
			height: auto;

			path {
				transition: fill 0.25s ease;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.sub-menu {

			li a {
				@include font-size(21);
			}
		}
	}

	@media screen and (max-width: 991px) {
		width: 100%;

		.sub-menu-wrapper {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #1D2C35;
			z-index: 3;
			overflow: auto;
			opacity: 1;
			padding: 102px 36px 50px 36px;
			transform: translateX(100%);
			transition: transform 0.25s ease;
		}

		.siteHeader__subBackBtn {
			display: block;
			background: transparent;
			border: none;
			margin: 0 0 64px;
			padding: 0 0 0 34px;
			@include font-size(12);
			font-weight: 300;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 0.04em;
			position: relative;
			
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 20px;
				height: 12px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='11.997' viewBox='0 0 20 11.997'%3E%3Cpath id='Union_9' data-name='Union 9' d='M12.5,1.5,16,5H0V7H16l-3.5,3.5L14,12l6-6L14,0Z' transform='translate(20 11.997) rotate(180)' fill='%23b2ebed'/%3E%3C/svg%3E%0A");
				background-size: cover;
			}
		}

		.sub-menu {

			li {
				margin-bottom: 15px;
			}
		}
	}
}