 html{
	font-size: 62.5%;
	font-family: $main-font;
	font-weight: 300;
	margin-top: 0 !important;
	overflow-x: hidden;

	&#BTT{
		margin-top: 0 !important;
	}

	&.side-open {
		overflow: hidden;
	}

	// &.menu-open{
	// 	overflow: hidden;
}

body{
	color: $colour;
	background-color: $main-bg-colour;
	@include font-size(16);
	@include line-height(16,28);
	font-weight: 300;
	position: relative;
	//overflow: hidden;

	@media (max-width: $b2){
		@include font-size(14);
		@include line-height(14,23);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	*{

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 300;
	margin-bottom: 1.5em;
	@include font-size(16);
	@include line-height(16,28);

	sub, 
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: $teal;

		&:hover{
			color: $teal-light;
			text-decoration: underline;
		}
	}

	strong{
		font-weight: 600;
		color: inherit;
	}

	// em{
	// 	font-style: normal;
	// 	color: $teal;
	// }
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	color: $blue-dark;
	font-family: $alt-font;
	margin-bottom: .45em;
	font-weight: 200;
}

h1{
	@include font-size(95);
	@include line-height(95,110);
	letter-spacing: -.03em;

	@media (max-width: $b1){
		@include font-size(80);
		@include line-height(80,95);
	}

	@media (max-width: $b2){
		@include font-size(40);
		@include line-height(40,50);
	}

	&.large{
		@include font-size(120);
		@include line-height(120,130);
		letter-spacing: -.03em;
		font-weight: 500;

		@media (max-width: $b1){
			@include font-size(90);
			@include line-height(90,100);
		}

		@media (max-width: $b2){
			@include font-size(44);
			@include line-height(44,56);
		}
	}
}

h2{
	@include font-size(76);
	@include line-height(76,80);

	@media (max-width: $b1){
		@include font-size(48);
		@include line-height(48,58);
	}

	@media (max-width: $b2){
		@include font-size(40);
		@include line-height(40,50);
	}
}

h3{
	@include font-size(61);
	@include line-height(61,70);
	margin-bottom: .77em;

	@media (max-width: $b1){
		@include font-size(48);
		@include line-height(48,58);
	}

	@media (max-width: $b2){
		@include font-size(40);
		@include line-height(40,50);
	}
}

h4{
	@include font-size(48);
	@include line-height(48,54);

	@media (max-width: $b1){
		@include font-size(40);
	}

	@media (max-width: $b2){
		@include font-size(34);
		@include line-height(34,48);
		margin-bottom: .8em;
	}
}

h5{
	@include font-size(39);
	@include line-height(39,46);
	margin-bottom: .95em;

	@media (max-width: $b2){
		@include font-size(28);
		@include line-height(28,39);
	}
}

h6{
	@include font-size(32);
	@include line-height(32,38);
	font-weight: 300;

	@media (max-width: $b2){
		@include font-size(28);
		@include line-height(28,39);
	}
}

p{
	@include font-size(16);
	@include line-height(16,28);

	@media (max-width: $b2){
		@include font-size(14);
		@include line-height(14,23);
	}

	&.large{
		@include font-size(20);
		@include line-height(20,28);
		font-weight: 400;
		margin-bottom: 2.35em;

		@media (max-width: $b2){
			@include font-size(18);
			@include line-height(18,28);
			margin-bottom: .95em;
		}
	}
}

mark{
	background-color: $teal;
	font-weight: 300;
	box-shadow: .05em 0 0 $teal, -.05em 0 0 $teal;
}

strong{
	font-weight: 600;
}

blockquote{
	@include font-size(39);
	@include line-height(39,50);
	letter-spacing: normal;
	font-family: $alt-font;
	margin: 0 auto .95em;
	font-weight: 200;
	position: relative;
	padding: 0;
	font-style: normal;
	color: $colour;
	background-position: 50% 0;
	padding: 81px 0px 46px;
	position: relative;
	z-index: 1;

	@media (max-width: $b2){
		@include font-size(21);
		@include line-height(21,27);
		padding: 53px 0px 35px;
		margin: 0 auto 1.35em;
	}

	&::after{
		content: '';
		position: absolute;
		width: 78px;
		height: 2px;
		background-color: $teal;
		left: 0;
		bottom: 0;

		@media (max-width: $b2){
			width: 38px;
		}
	}

	&:before{
		content: '“';
		position: absolute;
		top: .12em;
		left: 0em;
		font-size: 156.41%;
		line-height: 1;
		color: $teal;
		font-weight: 900;

		@media (max-width: $b2){
			font-size: 225.41%;
			top: .22em;
		}
	}

	~ .by{
		@include font-size(16);
		@include line-height(16,28);
		letter-spacing: .04em;
		font-weight: 500;

		@media (max-width: $b2){
			@include font-size(14);
			@include line-height(14,23);
			margin-bottom: .3em;
		}
	}

	~ .role{
		@include font-size(16);
		@include line-height(16,28);
		letter-spacing: .04em;
		font-weight: 300;
		margin-top: -.35em;

		@media (max-width: $b2){
			@include font-size(14);
			@include line-height(14,23);
		}
	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		margin-bottom: 0!important;
	}
}

a{
	font-size: inherit;
	color: $teal;
	font-weight: 300;
	transition: $time;
	cursor: pointer;
	text-decoration: none;

	*{
		transition: $time;
	}

	&:hover{
		color: $teal-light;
		text-decoration: none;
	}
}

hr{
	border: 0;
	width: 48px;
	height: 2px;
	margin: 1.74em auto 1.74em;
	background-color: $colour;
}

address{
	font-style: normal;
	@include font-size(16);
	color: $colour;
	font-weight: 300;
	margin-bottom: 1.45em;
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	@include font-size(0);
	margin-bottom: 14px;

	li,
	dt,
	dd{
		@include font-size(16);
		@include line-height(24,41);
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(14);
			@include line-height(14,23);
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(16);
	margin-bottom: 1.7em;

	@media (max-width: $b2){
		@include font-size(14);
		@include line-height(14,23);
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	ul{

		li{

			&:before{
				background-color: transparent;
			}
		}
	}

	li{
		padding-left: 16px;
		list-style: none;
		position: relative;
		margin-bottom: .1em;

		.white &{

			&:before{
				background-color: $white;
			}
		}

		&:before{
			content: '';
			position: absolute;
			left: 0em;
			top: .55em;
			width: 7px;
			height: 7px;
			border-radius: 0 2px 0 2px;
			background-color: $blue-dark;
		}

		li{

			&:before{
				background-color: transparent;
				border: 1px solid $colour;
			}
		}
	}
}

ul.sticky-menu,
ul.filter-items,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.matching-items,
ul.post-categories,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0px;
	counter-reset: section;

	li{
		padding-left: 1.7em;
		list-style: none;
		position: relative;
		margin-bottom: .1em;

		&:before{
			counter-increment: section;
			content: counter(section)".";
			position: absolute;
			right: calc(100% - .5em);
		}

		ol:not(.pagination){
			padding-left: 1.05em;
			margin-bottom: 2px;
		}
	}
}

table{
	width: 100%;
	background-color: transparent;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 6.8px 5px;
	@include font-size(16);
	@include line-height(16,28);
	line-height: 1.2;
	vertical-align: middle;
	text-align: center;
	font-weight: 300;
	color: $colour;

	@media (max-width: $b2){
		@include font-size(14);
		@include line-height(14,23);
	}

	tr{
		height: auto!important;
		padding: 0;
	}

	th{
		padding: 11px 5px;
		font-weight: 300;
		font-family: $main-font;
		color: $colour;
	}

	th,
	td{
		width: auto!important;
		height: auto!important;
	}
}

html#BTT body table{
	margin-bottom: 1em;
	border: none;
	padding: 10px 0;

	thead th{
		border: 1px solid $colour;
	}

	tbody td{
		border-bottom: 1px solid $colour;
		border-right: 1px solid $colour;

		&:first-of-type{
			border-left: 1px solid $colour;
			text-align: left;
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(15);
		font-weight: 600;
		color: $colour;

		@media (max-width: $b3){
			padding-left: 22px;
		}

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 14px;
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 2.45em 0 3.15em;
	}

	&.alignright {
		float:right;
		margin: 2.45em 0 3.15em 2.45em;
	}

	&.alignleft {
		float:left;
		margin: 2.45em 2.45em 3.15em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.45em auto 3.15em;
	}
}

a img.alignright {
	float:right;
	margin: 2.45em 0 3.15em 2.45em;
}

a img.alignleft {
	float:left;
	margin: 2.45em 2.45em 3.15em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.45em auto 3.15em;
}

::selection {
	background: darken($teal, 5%);
	color: white;
}

::-moz-selection {
	background: darken($teal, 5%);
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $blue-dark!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	padding-left: 14px;
	@include svg_img("../images/error-arrow");
	background-position: 0 0;
	margin-left: -2px;
	text-align: left;
}

.browsehappy{
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 999999999;
	background-color: white;
	margin-bottom: 0;
	width: 540px;
	margin-left: -270px;
	text-align: center;
	padding: 4px 2px;
	font-size: 13px;
	color: black;
	border-radius: 0 0 4px 4px;
	line-height: 13px;
	font-weight: 300;

	span{
		background-image: url(../images/browserhappy-cross.png);
		background-repeat: no-repeat;
		height: 13px;
		width: 13px;
		@include inline;
		vertical-align: middle;
		background-position: 50% 50%;

		&:hover{
			background-color: #f96059;
			transition: $time;
		}
	}

	strong{
		font-weight: 600;
	}

	a{
		color: $red;
		text-decoration: none;
		font-weight: 300;

		&:hover{
			color: $red;
			text-decoration: underline;
		}
	}
}

.ta{
	display: table;
	width: 100%;
	height: 1px;
}

.tc{
	display: table-cell;
	vertical-align: middle;
}

.flex{
	display: flex;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}

	&.top{
		align-self: flex-start;
	}

	&.bottom{
		align-self: flex-end;
	}

	&.no-wrap{
		flex-wrap: nowrap;
	}
}

pre{
	@include font-size(10);
}
