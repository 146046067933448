//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;

	section{
		overflow: hidden;
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

// .breadcrumb-con{
// 	@include font-size(18);
// 	line-height: 1.2em;
// 	margin-bottom: 50px;
// 	color: $colour;
// 	font-weight: 400;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: $b3){
// 		margin-bottom: 30px;
// 	}

// 	span{
// 		color: white;
// 		@include font-size(18);
// 		line-height: 1.2em;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}

// 	a{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		color: $red;
// 		font-weight: 400;
// 		margin-right: .2em;
// 		position: relative;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}

// 		&:after{
// 			content: '/';
// 			display: inline-block;
// 			margin-left: .4em;
// 			color: $colour;
// 		}


// 		&:hover,
// 		&:focus-visible{
// 			color: $red;
// 			text-decoration: none;
// 		}
// 	}

// 	> a{
// 		margin-left: 0;
// 	}

// 	.breadcrumb_last{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		color: $colour;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		font-weight: 400;
// 		position: relative;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}
// }

//
//!! BREADCRUM END
//

// .pagination-con{
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: flex-start;
// }

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4.25em;

	@media (max-width: $b3){
		margin-top: 1.25em;
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 7px;
		@include font-size(20);
		line-height: 28px;
		position: relative;
		z-index: 1;


		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					color: $teal;
				}
			}

			span,
			a{
				@include font-size(20);
				line-height: 28px;
				display: block;
				color: $blue-medium;
				font-weight: 300;
				padding: 0;

				&:hover,
				&:focus-visible{
					text-decoration: none;
					color: $teal;
				}
			}


			&.ellipse{
				color: $colour;
				font-weight: 600;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				text-decoration: none;
				background-position: 50% 37%;
				background-repeat: no-repeat;
				height: 28px;
				width: 14px;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{
			
			a{
				background-image: url(../images/arrow-right.svg);
				background-position: 52% 50%;

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-right-teal.svg);
				}
			}
		}

		&.prev{
			
			a{
				background-image: url(../images/arrow-left.svg);
				background-position: 48% 50%;

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-left-teal.svg);
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		20;
$social-addition:	27;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		border-radius: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $blue-dark;
		text-decoration: none;
		padding: 0;
		font-weight: 100;
		margin: 0 17px 17px 0;
		background-color: rgba(white, .5);

		@media (max-width: $b3){
			@include font-size(16);
			line-height: 38px;
			height: 38px;
			min-width: 38px;
			border-radius: 38px;
			margin: 0 10px;
		}

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			
			i{
				color: $white!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;

			@media (max-width: $b3){
				@include font-size(16);
				line-height: 38px;
				min-width: 38px;
			}
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

	
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.sub{
	font-weight: 500;
	text-transform: uppercase;
	position: relative;
	padding-left: 108px;
	letter-spacing: .04em;
	margin-bottom: 2.1em;
	color: $blue-dark;
	min-height: 2px;

	@media (max-width: $b1){
		padding-left: 58px;
	}

	@media (max-width: $b3){
		padding-left: 58px;
		@include font-size(12);
		margin-bottom: 1.3em;
	}

	.waypoint &{

		.line{
			max-width: 0px;
			overflow: hidden;
		}
	}

	.waypoint.animate &{

		.line{
			max-width: 78px;
			transition: $time*2 $time;

			@media (max-width: $b1){
				max-width: 39px;
			}
		}
	}

	&.bottom{
		margin-bottom: 1em;
		margin-top: .1em;
	}

	.line{
		position: absolute;
		width: 78px;
		height: 2px;
		background-color: $teal;
		left: 0;
		top: 50%;
		
		@media (max-width: $b1){
			width: 39px;
		}
	}
}

.hero-with-image-section {
	@media screen and (min-width: 1440px) {

		.waypoint.animate {
			
			.sub-con {
				transform: translateY(0);
			}

			.sub {
				transform: translateY(0);
			}
		}

		.sub-con {
			// overflow: hidden;
			transform: translateY(4rem);
			transition: 0.25s 0.4s !important;
		}

		.sub {
			margin-bottom: 2rem !important;
			transform: translateY(150%);
			transition: transform 0.25s 0.4s !important;

			.line {
				transition: max-width 0.5s 0.5s !important;
			}
		}

		.sub h1, .sub h2, .sub h3, .sub h4, .sub h5, .sub h6 {
			transition: all 0.25s;
		}
	}
}

.title-con-animated {

	.waypoint &{
		overflow: hidden;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			transform: translateY(100%);
			transition: $time;
		}
	}

	.animate &{

		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			transform: translateY(0%);
			transition: $time $time;
		}
	}
}

section{

	img,
	.img-con{

		&.tl{
			border-radius: 144px 0 0 0;

			@media (max-width: $b3){
				border-radius: 97px 0 0 0;
			}
		}

		&.tr{
			border-radius: 0 144px 0 0;

			@media (max-width: $b3){
				border-radius: 0 97px 0 0 ;
			}
		}

		&.br{
			border-radius: 0 0 144px 0;

			@media (max-width: $b3){
				border-radius: 0 0 97px 0;
			}
		}

		&.bl{
			border-radius: 0 0 0 144px;

			@media (max-width: $b3){
				border-radius: 0 0 0 97px;
			}
		}
	}
}

.hero-section{

	.row{
		padding-top: 213px;
		padding-bottom: 100px;
		min-height: 100vh;

		@media (max-width: 1024px){
			min-height: 0;
		}

		@media (max-width: $b3){
			padding-top: 247px;
			padding-bottom: 207px;
			min-height: 0;
		}
	}

	h1{
		margin-bottom: 0;
	}


	.text-slide{
		//pointer-events: none;

		.swiper-wrapper{
			//transition-duration: 0s!important;
		}

		&:not(.ready){

			.swiper-slide{
				//height: auto!important;
			}
		}

		.swiper-slide{
	
			&.swiper-slide-active{

				h1{
					transform: translateY(0px);
					transition: .5s;
				}
			}

			.h1-con{
				overflow: hidden;
			}

			h1{
				//min-height: 2.16666em;
				margin-bottom: .1em;
				transform: translateY(100%);
			}

			.btn{
				margin-top: 5.6em;

				@media (max-width: $b3){
					margin-top: 2.95em;
				}
			}
		}
	}
}

.hero-with-image-section{
	background-position: 50% 0%;
	
	*{
		color: white;
	}

	.row{
		padding-top: 305px;
		padding-bottom: 50px;
		min-height: 900px;

		@media (max-width: $b3){
			padding-top: 369px;
			padding-bottom: 15px;
			min-height: 730px;
		}
	}

	.sub{
		margin-bottom: 3.6em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}
	}

	.btn{
		margin-top: 7.5em;

		@media (max-width: $b3){
			margin-top: 1.2em;
		}
	}

	.text{

		@media (min-width: $b3 + 1){
			flex: 0 0 76%;
			max-width: 76%;
		}
	}
}

.hero-with-text-section{
	
	.row{
		padding-top: 211px;
		padding-bottom: 114px;

		@media (max-width: $b3){
			padding-top: 146px;
			padding-bottom: 67px;

			.sub{
				margin-bottom: 1.5em;
			}
		}
	}

	@media screen and (min-width: 1440px) {

		.waypoint.animate {
			
			.sub-con {
				transform: translateY(0);
			}

			.sub {
				transform: translateY(0);
			}
		}

		.sub-con {
			// overflow: hidden;
			transform: translateY(4rem);
			transition: 0.25s 0.4s !important;
		}

		.sub {
			margin-bottom: 2rem !important;
			transform: translateY(150%);
			transition: transform 0.25s 0.4s !important;

			.line {
				transition: max-width 0.5s 0.5s !important;
			}
		}

		.sub h1, .sub h2, .sub h3, .sub h4, .sub h5, .sub h6 {
			transition: all 0.25s;
		}
	}
}


.hero-section + .title-with-text-section{
	padding-top: 0;
}

.team-section,
.services-section,
.newsroom-section,
.title-with-text-section{
	padding-top: 186px;
	padding-bottom: 174px;

	@media (max-width: $b3){
		padding-bottom: 57px;
	}

	.text{

		@media (min-width: $b3 + 1){
			margin-top: -.5em;
		}
		
		@media (min-width: $b1 + 1){
			padding-left: 231px;
		}
	}
}

.newsroom-section{
	padding-top: 105px;
	padding-bottom: 151px;

	@media (max-width: $b3){
		padding-top: 0px;
		padding-bottom: 37px;
	}

	h3{
		margin-bottom: .45em;

		@media (max-width: $b2){
			@include font-size(34);
			@include line-height(34,48);
			margin-bottom: .9em;
		}
	}

	.sub + .btn{
		margin-top: 3.4em;

		@media (max-width: $b3){
			margin-top: 1.2em;
			margin-bottom: 2.3em;
		}
	}

	.row.bottom{
		padding-top: 100px;

		@media (max-width: $b3){
			padding-top: 0;
		}

		@media (min-width: $b2 + 1){

			.n-item{
				margin-bottom: 3.2em;
				
				.img-con{
					width: 38.3%;
					padding-top: 29.5%;
				}

				.text-con{
					width: 61.7%;
					padding-left: 30px;

					h5{
						width: 500px;
					}

					&:after{
						left: 30px;
					}
				}
			}
		}

		@media screen and (min-width: $b2 + 1) and (max-width: 1440px) {
			padding-left: 0;
			// .n-item .img-con {
			// 	width: 100%;
			// 	padding-top: 59.5%;
			// }

			// .n-item .text-con {
			// 	padding: 20px 0 0 0;
			// }

			// .n-item .text-con::after {
			// 	left: 0;
			// }

			// .n-item .inner .text-con .date {
			// 	top: 18px;
			// }
		}

		// @media (min-width: 1401px){
		// 	padding-left: 0;
		// }
	}
}

.slide-over-sections-section.slide-over-sections-section{
	background-color: $blue-dark;
	position: relative;
	overflow: visible;

	.sections{
		width: 100%;
		overflow: hidden;
		position: sticky;
		top: 0;
		background-color: $blue-dark;

		// .col-12.waypoint.animate {
		// 	opacity: 1;
		// 	transform: translateY(0)
		// }

		// .col-12 {
		// 	opacity: 0;
		// 	transform: translateY(100px);
		// 	transition: opacity 0.5s ease,
		// 							transform 0.5s ease;
		// }
	}

	.text{

		@media (min-width: $b3 + 1){
			flex: 0 0 33.33%;
			max-width: 33.33%;
		}

		*{
			color: white;
		}

		.sub{
			margin-bottom: 3em;

			@media (max-width: $b3){
				margin-bottom: 2.6em;
			}
		}

		.btn{
			margin-top: .8em;

			@media (max-width: $b3){
				margin-top: .5em;
			}
		}

	}

	.title-side{
		display: flex;
		align-items: center;
		align-self: stretch;
		position: relative;

		@media (min-width: $b3 + 1){
			padding-left: 122px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 66.64%;
			max-width: 66.64%;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 39%;
			width: 61.2%;
			background-image: url(../images/section-back.svg);
			background-size: 18.18% auto;

			@media (max-width: $b3){
				width: 100%;
				left: 0;
				margin-top: -31px;
				z-index: -1;
				pointer-events: none;
			}
		}

		.title{
			@include font-size(300);
			line-height: 1;
			letter-spacing: -.03em;
			font-weight: 600;
			color: $blue;
			text-shadow: 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter, 0 0 2px $blue-lighter;
			position: relative;
			z-index: 2;

			@media (max-width: $b2){
				@include font-size(150);
				padding-top: 28px;
				padding-bottom: 62px;
				pointer-events: none;
				text-shadow: 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 2px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 1.5px $blue-lighter, 0 0 2px $blue-lighter;
			}
		}
	}

	.row{
		min-height: 100vh;
		align-items: center;

		@media (max-width: $b3){
			align-items: flex-start;
			min-height: 0;
			padding-top: 82px;
		}
	}
}

.cta-section + .text-with-image-section{
	padding-top: 114px;

	@media (max-width: $b3){
		padding-top: 0;	
	}
}

.team-section + .text-with-image-section{
	padding-top: 42px;

	@media (max-width: $b3){
		padding-top: 0;
	}
}

main.main-wrapper section{

	&.text-with-image-section:last-of-type{
		padding-bottom: 200px;

		@media (max-width: $b3){
			padding-bottom: 80px;
		}
	}
}

.text-with-image-section{
	padding-top: 200px;
	padding-bottom: 100px;

	@media (max-width: $b3){
		padding-top: 0;
		padding-bottom: 80px;
	}

	&:first-child{
		padding-top: 208px;

		@media (max-width: $b3){
			padding-top: 148px;
			padding-bottom: 0px;
			
			.image-side{
				order: 2;
				margin-top: 1.3em;
				margin-bottom: 0;
			}
		}

		.sub{
			margin-top: .15em;
			margin-bottom: 2.4em;

			@media (max-width: $b3){
				margin-bottom: 1.5em;
			}
		}

		.row{
			align-items: flex-start;
		}

		.title-con-animated h3{
			@include font-size(76);
			@include line-height(76,80);
			margin-bottom: 0.83em;

			@media (max-width: $b1){
				@include font-size(48);
				@include line-height(48,58);
			}
			
			@media (max-width: $b2){
				@include font-size(40);
				@include line-height(40,50);
				margin-bottom: 1.13em;
			}
		}

		p{
			margin-bottom: 2.2em;

			@media (max-width: $b3){
				margin-bottom: 1.6em;
			}
		}
	}

	+ .text-with-image-section{
		padding-top: 100px;

		@media (max-width: $b3){
			padding-top: 0;
		}
	}

	&.right{

		.text-side{

			@media (min-width: $b1 + 1){
				padding-left: 123px;
			}
		}
	}

	&.left{

		@media (min-width: $b3 + 1){
			
			.image-side{
				order: 2;
			}
		}
		
		.text-side{

			@media (min-width: $b1 + 1){
				padding-right: 123px;
			}
		}
	}
	
	.image-side{

		@media (max-width: $b3){
			padding-right: 0;
			padding-left: 0;
			margin-bottom: 2.7em;

			img{
				border-radius: 0 0 96px 0;
			}
		}
	}

	.text-side{

		h3{

			@media (max-width: $b2){
				@include font-size(34);
				@include line-height(34,48);
				margin-bottom: .9em;
			}
		}
	}

	.sub{
		margin-bottom: 2.7em;

		@media (max-width: $b3){
			margin-bottom: 1.3em;
		}
	}

	.title-con-animated + .sub-con .sub{
		margin-bottom: 3em;
	}

	.text{
		padding-left: 108px;

		@media (max-width: $b1){
			padding-left: 58px;
		}

		@media (max-width: $b3){
			padding-left: 0;
		}
	}

	.btn{
		margin-top: 2.7em;

		@media (max-width: $b3){
			margin-top: 1.5em;
		}
	}
}

.services-section{
	padding-top: 255px;
	padding-bottom: 50px;

	@media (max-width: $b3){
		padding-top: 82px;
		padding-bottom: 50px;

		.sub.bottom{
			margin-bottom: 2.5em;
		}
	}

	h3{
		margin-bottom: .78em;
	}

	.row.services{
		padding-top: 65px;
		margin-right: -90px;
		margin-left: -90px;

		@media (max-width: $b3){
			padding-top: 18px;
			margin-right: -60px;
			margin-left: -76px;
		}

		@media screen and (max-width: 991px) {
			margin-left: 0;
			margin-right: 0;
			justify-content: space-between;

			.service {
				padding-left: 0;
				padding-right: 0;
				width: calc(50% - 40px);
				flex: unset;
			}
		}

		@media screen and (max-width: 768px) {
			margin-right: -60px;
			margin-left: -76px;

			.service {
				width: 100%;
				padding-right: 20px;
				padding-left: 36px;
			}
		}
	}

	.service{
		margin-bottom: 80px;

		@media (min-width: $b2 + 1){
			padding-right: 40px;
			padding-left: 40px;
		}

		@media (max-width: $b2){
			transform: none!important;
		}

		@media (max-width: $b3){
			transform: none!important;
			margin-bottom: 0;
		}

		@media (min-width: $b2 + 1){
			
			&:nth-of-type(1){
				margin-top: 100px;
			}
	
			&:nth-of-type(3){
				margin-top: 300px;
			}
	
	
			&:nth-of-type(3n + 4){
				margin-top: -200px;
			}
	
			&:nth-of-type(3n + 5){
				margin-top: -300px;
			}
	
			&:nth-of-type(3n + 6){
				margin-top: 0px;
			}
		}
		
		.inner{
			border: 1px solid rgba($blue-medium, .4);
			padding: 273px 39px 63px;
			position: relative;
			border-radius: 0 0 0 0;
			transition: $time*2;
			overflow: hidden;
			min-height: 613px;

			@media (max-width: $b3){
				padding: 101px 37px 6px;
				min-height: 223px;
				border-right: none;
				border-left: none;
			}

			@media (min-width: $b2 + 1){
				
				&:hover,
				&:focus-visible{
					background-color: $blue-medium;
					border-radius: 0 0 144px 0;
				}
			}
			
			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;

				~ *{
					transition: $time*2;
				}

				&:after{
					content: '';
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					max-width: 0%;
					height: 10px;
					background-color: $teal;
					transition: $time*2;
					overflow: hidden;
				}

				@media (min-width: $b2 + 1){

					&:hover,
					&:focus-visible{

						&:after{
							max-width: 100%;
						}
						
						~ .title{
							transform: translateX(-100%);
							transform: translateX(calc(-100% + 311px));
						}

						~ h5{
							color: rgba(white, 1);

							@media (max-width: $b3){
								color: $blue-dark;
							}
						}
						
						~ p{
							color: rgba(white, .75);
						}

						~ svg{
							transform: translateX(78px);
						}
					}
				}
			}

			p{
				width: 290px;

				@media (max-width: $b3){
					@include font-size(16);
					@include line-height(16,28);
				}
			}

			.title{
				text-shadow: 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2), 0 0 2px rgba($blue-medium, .2);
				color: white;
				@include font-size(200);
				line-height: 1;
				letter-spacing: -.03em;
				font-weight: 600;
				max-width: none;
				margin-bottom: .19em;
				white-space: nowrap;
				left: 49px;
				position: absolute;
				top: 35px;
				transform: translateX(0%);

				@media (max-width: $b2){
					text-shadow: unset;
					-webkit-text-stroke-width: 1px;
  				-webkit-text-stroke-color: $blue-medium;
					opacity: 0.2;
				}

				@media (max-width: $b3){
					@include font-size(150);
					top: 26px;
					margin-bottom: 0;

					+ h5{
						z-index: 1;
						position: relative;
						margin-bottom: .6em;
					}
				}
			}

			svg{
				position: absolute;
				bottom: 39px;

				@media (max-width: $b3){
					bottom: 33px;
				}

				&.on-arrow{
					left: -39px;

					@media (max-width: $b3){
						left: -67px;
					}

					*{
						fill: $teal;
					}
				}

				&.off-arrow{
					right: 39px;

					@media (max-width: $b3){
						right: 27px;
					}

					*{
						fill: $blue-medium;
					}
				}
			}
		}
	}
}

.accordion-section,
.cta-section{
	padding-top: 74px;
	padding-bottom: 50px;

	@media (max-width: $b3){
		padding-top: 34px;
		padding-bottom: 80px;

		h3{
			margin-bottom: .97em;
		}
	}

	.btn-side{
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		@media (max-width: $b3){
			justify-content: center;
		}

		.btn{
			margin-top: -3px;

			@media (max-width: $b3){
				width: 100%;
				justify-content: center;
				padding: 18px 102px 18px 135px;

				svg{
					left: 104px;
				}

				&:hover,
				&:focus-visible{
					padding: 18px 135px 18px 102px;
					
					svg{
						left: calc(100% - 125px);
					}
				}
			}
		}
	}
}

.cta-section{

	.single:not(.single-services) &{
		padding-top: 53px;
		padding-bottom: 48px;

		@media (max-width: $b3){
			padding-top: 40px;
			padding-bottom: 23px;
		}

		.row{
			align-items: center;
		}

		h4{
			margin-bottom: .1em;

			@media (max-width: $b2){
				@include font-size(40);
				@include line-height(40,50);
			}

			@media (max-width: $b3){
				margin-bottom: 1.2em;
			}
		}
		
		.container{
			position: relative;

			&:before,
			&:after{
				content: '';
				position: absolute;
				right: 15.5px;
				left: 15.5px;
				height: 1px;
				background-color: rgba($blue-medium, .2);

				@media (max-width: $b3){
					right: 20px;
					left: 36px;
				}
			}	

			&:before{
				top: -53px;
			}

			&::after{
				bottom: -48px;

				@media (max-width: $b3){
					display: none;
				}
			}
		}
	}
}

.arrow-link{
	display: flex;
	align-items: center;
	color: $blue-medium;
	font-weight: 600;
	margin-top: 4.05em;
	letter-spacing: .05em;
	will-change: transform;
	-webkit-font-smoothing: subpixel-antialiased;
	font-family: $font-new;

	@media (max-width: $b3){
		margin-top: 1.95em;
	}

	&:hover,
	&:focus-visible{
		color: $blue-medium;
		
		svg{
			transform: translateX(10px);
		}
	}

	svg{
		margin-right: 1.3em;
		transform: translateX(0px);

		*{
			fill: $teal;
		}
	}
}

.image-with-blocks-section{
	padding-bottom: 126px;

	@media (max-width: $b3){
		padding-bottom: 0px;
	}

	.img-con{
		margin-bottom: 4.2em;

		@media (max-width: $b3){
			margin-bottom: 1em;
		}

		img{
			border-radius: 0 144px 0 0;

			@media (max-width: $b3){
				border-radius: 0 96px 0 0;
				margin-left: -36px;
				width: calc(100% + 36px);
				max-width: calc(100% + 36px);
				min-height: 270px;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		img + .title-con{
			margin-top: -104px;
			padding: 54px 54px 0 0;
			width: 618px;

			@media (max-width: $b3){
				margin-top: -55px;
				padding: 21px 0px 0 36px;
				width: 282px;
				margin-left: -36px;
			}
		}

		.title-con{
			display: inline-block;
			background-color: white;
			
			
			.title-con-animated + .sub-con{
				margin-top: 1.7em;

				@media (max-width: $b3){
					margin-top: .1em;
				}
			}
		}
	}

	.col-lg-4{

		@media (max-width: $b2){
			margin-bottom: 57px;

			&:last-of-type:nth-of-type(even){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (min-width: $b1 + 1){
			padding-right: 19px;
			padding-left: 123px;
		}

		@media (min-width: $b3 + 1){
			
			&:nth-of-type(2){
				transition-delay: .1s;
			}

			&:nth-of-type(3){
				transition-delay: .2s;
			}

			&:nth-of-type(4){
				transition-delay: .3s;
			}

			&:nth-of-type(5){
				transition-delay: .4s;
			}

			&:nth-of-type(6){
				transition-delay: .5s;
			}

			&:nth-of-type(7){
				transition-delay: .6s;
			}
		}
	}
}

.accordion-section{
	padding-top: 74px;
	padding-bottom: 74px;

	@media (max-width: $b3){
		padding-top: 23px;
		padding-bottom: 0px;
	}

	.sub{
		margin-bottom: 2.7em;

		@media (max-width: $b3){
			margin-bottom: 1.4em;
		}
	}

	.btn-side{

		.btn{
			margin-top: 3px;
		}
	}

	.title-side{
		margin-bottom: 2.3em;

		@media (max-width: $b3){
			margin-bottom: .2em;
		}
	}

	.img-side{

		@media (min-width: $b2 + 1){
			padding-left: 123px;
		}

		@media (max-width: $b3){
			
			img{
				margin-left: -37px;
				width: calc(100% + 37px + 20px);
				max-width: calc(100% + 37px + 20px);
				height: 437px;
				border-radius: 0 97px 0 0;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	dl.accordion{
		counter-reset: section;

		dt{
			border-top: 1px solid $grey-light;
			padding: 40px 40px 40px 108px;
			font-weight: 200;
			@include font-size(25);
			@include line-height(25,33);
			color: $blue-dark;
			position: relative;
			cursor: pointer;
			will-change: transform;
			transition: $time;

			@media (max-width: $b3){
				padding: 13px 40px 31px 28px;
				@include font-size(21);
				@include line-height(21,27);
			}

			&:before{
				counter-increment: section;
				content: "0"counter(section)".";
				position: absolute;
				top: 0;
				left: 0;
				width: 78px;
				border-top: 2px solid $grey-light;
				@include font-size(16);
				@include line-height(16,28);
				display: flex;
				justify-content: center;
				padding-top: 43px;
				font-weight: 400;
				transition: $time;

				@media (max-width: $b3){
					@include font-size(12);
					@include line-height(12,28);
					width: 18px;
					padding-top: 9px;
				}
			}

			&::after{
				content: '';
				position: absolute;
				top: 41px;
				right: 0;
				width: 20px;
				height: 20px;
				background-image: url(../images/plus.svg);
				background-repeat: no-repeat;
				transform: rotate(0deg);
				transition: $time;

				@media (max-width: $b3){
					top: 18px;
					width: 16px;
					height: 16px;
					background-size: 100% 100%;
				}
			}

			&.open{
				color: $teal;

				&:before{
					color: $teal;
				}

				&::after{
					transform: rotate(45deg);
				}

				+ dd{
					max-height: 9999px;
					transition: $time;
				}
			}
		}

		dd{
			margin: 0;
			max-height: 0;
			overflow: hidden;

			.inner{
				padding: 2px 100px 39px 108px;

				@media (max-width: $b3){
					padding: 0px 0px 23px 28px;
					margin-top: -6px;
				}
			}

			.arrow-link{
				margin-top: 2.2em;

				@media (max-width: $b3){
					margin-top: -.3em;
				}
			}
		}
	}
}

.quote-section{
	padding-top: 130px;
	padding-bottom: 129px;

	@media (max-width: $b3){
		padding-top: 60px;
		padding-bottom: 45px;
	}

	.container{
		max-width: 863px;
	}
}

.inside-cennox-section{
	margin-top: -123px;
	margin-bottom: -8px;

	@media (max-width: $b3){
		margin-top: 0px;
		margin-bottom: 70px;

		.swiper-wrapper-con{
			overflow: visible!important;
		}
	}

	.row{
		z-index: 2;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15.5px;
			width: 100vw;
			max-width: 100vw;
			background-color: white;
		}
	}
	
	.swiper-slide{

		.inner{
			position: relative;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				&:hover,
				&:focus-visible{

					~ *{
						transition: $time;
					}
					
					~ h6{
						color: $teal;

						&:before{
							background-color: $teal;
						}
					}
				}
			}
		}

		.img-con{
			padding-top: 64.3%;
			border-radius: 24px 0 24px 0;
			margin-bottom: .85em;

			@media (max-width: $b3){
				margin-bottom: 0.65em;
			}
		}

		h6{
			@include font-size(25);
			@include line-height(25,33);
			position: relative;
			font-weight: 200;
			padding-left: 16px;
			width: 270px;
			max-width: 100%;

			@media (max-width: $b2){
				@include font-size(21);
				@include line-height(21,27);
			}

			&:before{
				content: '';
				position: absolute;
				left: 0em;
				top: .55em;
				width: 7px;
				height: 7px;
				border-radius: 0 2px 0 2px;
				background-color: $blue-dark;
				transition: $time;
			}
		}
	}

	.col-12{
		padding-left: 93px;

		@media (max-width: $b3){
			padding-left: 36px;
		}
	}

	.sub-con{
		margin-top: 2.15em;

		@media (max-width: $b3){
			margin-top: 1.95em;
		}

		.sub{
			margin-bottom: 2.2em;
		}
	}
}

.our-history-slide-section{
	padding-top: 196px;
	padding-bottom: 100px;
	background-color: $blue-dark;
	margin-top: 100px;

	@media (max-width: $b3){
		padding-top: 76px;
		padding-bottom: 100px;
		margin-top: -22px;

		h3{
			@include font-size(34);
			@include line-height(34,48);
		}
	}

	*{
		color: white;
	}

	.sub{
		margin-bottom: 2.7em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}
	}

	.title-side{
		margin-bottom: 5em;

		@media (max-width: $b3){
			margin-bottom: 3.9em;
		}
	}

	.history-slide-con{

		@media (min-width: $b3 + 1){
			flex: 0 0 83.3%;
			max-width: 83.3%;
		}

		@media (max-width: $b3){
			padding-right: 0;
			padding-left: 0;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.history-slide{
		position: relative;

		@media (max-width: $b3){
			order: 2;
			width: 100%;
		}

		.swiper-slide{

			.inner{
				width: 39.5%;
				padding-top: 52px;
				min-height: 571px;
				margin-bottom: 101px;

				@media (max-width: $b3){
					width: 100%;
					padding-left: 36px;
					padding-right: 20px;
					padding-top: 61px;
					min-height: 0px;
					margin-bottom: 0px;
				}

				.sub{
					@include font-size(14);
					font-weight: 300;
					margin-bottom: 1.4em;

					.line{
						background-color: white;
					}
				}

				h5{
					margin-bottom: 1.1em;

					@media (max-width: $b2){
						@include font-size(21);
						@include line-height(21,27);
						margin-bottom: 1.4em;
					}
				}
			}
		}

		.swiper-pagination-fraction{
			color: #738591;
			@include font-size(14);

			.swiper-pagination-total{
				color: #738591;
			}

			.swiper-pagination-current{
				color: white;
			}
			
		}

		.swiper-pagination{
			left: 0;
			width: calc(100% - 178px);
			transform: none;
			counter-reset: section;
			justify-content: flex-start;
			@include font-size(14);
			letter-spacing: .04em;
			line-height: 78px;
			top: 100%;

			@media (max-width: $b3){
				padding-left: 36px;
				width: calc(100% - 160px);
				line-height: 54px;
			}

			.swiper-pagination-bullet{
				width: auto;
				height: auto;
				background-color: transparent;
				border-radius: 0;
				margin: 0 19px 0 0;
				color: rgba(white, .5);
				position: relative;


				@media (min-width: $b3 + 1){
					
					&:before{
						content: '';
						height: 1px;
						max-width: 0;
						width: 77px;
						overflow: hidden;
						background-color: white;
						position: absolute;
						top: 50%;
						left: 38px;
						transform: translateY(-50%);
					}
				}

				
				
				&:after{
					counter-increment: section;
					content: '0'counter(section);

					@media (max-width: $b3){
						content: counter(section);
					}
				}

				&.swiper-pagination-bullet-active{
					color: rgba(white, 1);
					transition: $time;

					@media (min-width: $b3 + 1){
						width: 116px;

						&:before{
							max-width: 77px;
							transition: $time;
						}
					}
				}
			}
		}


		.swiper-button-disabled {
			opacity: 0 !important;
		}

		.swiper-button-prev{
			right: 90px;
			left: auto;

			@media (max-width: $b3){
				right: 94px;
			}
		}

		.swiper-button-next{
			right: 0;

			@media (max-width: $b3){
				right: 20px;
			}
		}
	}

	.history-image-slide{
		position: absolute;
		top: 0;
		right: 15.5px;
		width: 47.1%;
		pointer-events: none;

		@media (max-width: $b3){
			position: static;
			width: 100%;
			order: 1;
		}

		.swiper-slide{

			.img-con{
				height: 571px;

				@media (max-width: $b3){
					height: 337px;
				}
			}
		}
	}

	@media screen and (min-width: 769px) {
		.history-slide {

			.swiper-slide {
				opacity: 0;
			}
	
			.swiper-slide-active {
				opacity: 1;
			}
		}
	
		.history-image-slide {
	
			.swiper-wrapper-con {
				overflow: visible !important;
			}
	
			.swiper-slide {
				opacity: 0;
			}
	
			.swiper-slide-active {
				opacity: 1;
				transform: translateZ(0) translateX(0) !important;
			}
	
			.swiper-slide-next {
				opacity: 1;
				transform: translateZ(0) translateX(300px) !important;

				@media screen and (max-width: 1600px) {
					transform: translateZ(0) translateX(250px) !important;
				}

				@media screen and (max-width: 1500px) {
					transform: translateZ(0) translateX(200px) !important;
				}

				@media screen and (max-width: 1400px) {
					transform: translateZ(0) translateX(150px) !important;
				}

				@media screen and (max-width: 1300px) {
					transform: translateZ(0) translateX(100px) !important;
				}
			}
		}
	}
}

.our-history-stats-section{
	padding-top: 175px;
	padding-bottom: 129px;
	background-color: $blue-dark;

	@media (max-width: $b3){
		padding-top: 110px;
		padding-bottom: 26px;
	}

	*{
		color: white;
	}

	.sub{
		margin-bottom: 2.7em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}
	}

	.title-side{
		margin-bottom: 4.2em;

		@media (max-width: $b3){
			margin-bottom: 5.6em;
		}

		h3{
			width: 450px;

			@media (max-width: $b2){
				@include font-size(34);
				@include line-height(34,48);
			}
		}
	}

	.stats{
		margin-bottom: 5.2em;

		@media (max-width: $b3){
			margin-bottom: 3.8em;
		}

		&:nth-of-type(2n + 2){
			
			@media (min-width: $b2 + 1){
				padding-right: 124px;
			}
		}

		&:nth-of-type(2n + 3){
			
			@media (min-width: $b2 + 1){
				padding-left: 124px;
			}
		}

		.stat{
			@include font-size(120);
			line-height: 1;
			letter-spacing: -.04em;
			color: $blue-dark;
			text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
			font-weight: 600;
			width: 100%;
			border-bottom: 1px solid white;
			padding-bottom: .2em;

			@media (max-width: $b1){
				@include font-size(80);
				border-bottom: none;
				padding-bottom: 0;
			}
			
			*{
				@include font-size(120);
				line-height: 1;
				letter-spacing: -.04em;
				color: $blue-dark;
				text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
				font-weight: 600;

				@media (max-width: $b1){
					@include font-size(80);
				}
			}
		}

		.under{
			padding-top: 1.1em;
			padding-bottom: 1.1em;

			@media (max-width: $b3){
				padding-top: 1em;
				padding-bottom: 0;
			}
		}
	}
}

.team-section{
	padding-top: 85px;
	padding-bottom: 85px;

	@media (max-width: $b3){
		padding-top: 8px;
		padding-bottom: 35px;
	}

	h3{
		width: 400px;
		margin-bottom: .96em;

		@media (max-width: $b2){
			@include font-size(34);
			@include line-height(34,48);
			width: 300px;
			margin-bottom: .56em;
		}
	}

	.title{
		margin-bottom: 4em;

		@media (max-width: $b3){
			margin-bottom: 1.1em;
		}
	}

	.row.bottom{
		
		@media (min-width: $b2 + 1){
			margin-right: -90px;
			margin-left: -90px;
		}

		@media (max-width: $b3){
			padding-top: 108px;
			margin-right: -40px;
			margin-left: -40px;
		}
	}

	.team{
		padding-right: 40px;
		padding-left: 40px;
		margin-bottom: 54px;

		@media (max-width: $b3){
			margin-bottom: 38px;
		}

		.img-con,
		img{
			margin-bottom: 2.45em;

			@media (max-width: $b3){
				margin-bottom: 1.45em;
			}
		}

		.img-con{
			padding-top: 118.577%;
		}

		.sub.bottom{
			margin-bottom: 1.5em;

			@media (max-width: $b3){
				margin-bottom: 1.3em;
			}

			.line{
				width: 39px;
			}
		}

		h6{
			font-weight: 200;
			margin-bottom: .17em;

			+ h6{
				@include font-size(25);
				margin-bottom: .85em;

				@media (max-width: $b2){
					@include font-size(21);
					margin-bottom: .95em;
				}
			}
		}
	}
}

.news-section{
	padding-top: 125px;
	padding-bottom: 134px;

	@media (max-width: $b3){
		padding-top: 0px;
		padding-bottom: 42px;
		margin-top: -40px;

		.sub.bottom{
			display: none;
		}
	}

	h3{
		margin-bottom: .45em;

		@media (max-width: $b2){
			@include font-size(34);
			@include line-height(34,48);
		}
	}

	.col-md-6{
		margin-bottom: 4.1em;

		@media (max-width: $b3){
			margin-bottom: 1em;
		}
	}

	.filter-side{
		display: flex;
		justify-content: flex-end;

		select{
			padding-right: 44px;
			width: auto;
			min-width: 190px;
			color: $blue-medium;
			line-height: 68px;
			height: 70px;
			border: 1px solid rgba($blue-medium, .4);
			padding-left: 27px;
			margin-top: 10px;
			@include font-size(16);
			letter-spacing: .05em;
			font-weight: 600;
			text-transform: uppercase;
			cursor: pointer;
			background-image: url(../images/select-icon-alt.svg);
			transition: background-color 0.3s ease,
									color 0.3s ease;

			&:hover {
				background-image: url(../images/select-icon-alt-white.svg);
				background-color: $blue-medium;
				color: #ffffff;
			}

			@media (max-width: $b3){
				line-height: 52px;
				height: 54px;
				width: 100%;
				margin-bottom: 27px;
			}
		}
	}

	.ajax-load-more-wrap {
		width: 100%;
	}

	// .alm-btn-wrap {
	// 	display: none;
	// 	height: 0;
	// 	overflow: hidden;
	// 	padding: 0;
	// 	margin: 0;
	// }
}

.n-item{
	margin-bottom: 4.431em;

	@media (max-width: $b3){
		margin-bottom: 1.1em;
	}

	&.small{

		.inner{

			.img-con{
				width: 100%;
				padding-top: 77.2%;
				margin-bottom: .85em;

				@media (max-width: $b3){
					margin-bottom: 0.65em;
				}
			}

			.text-con{
				width: 100%;
				padding-left: 0;

				&:after{
					display: none;
				}

				.cats{
					margin-bottom: .5em;

					@media (max-width: $b3){
						margin-bottom: .9em;
					}
				}

				h5{
					@include font-size(32);
					@include line-height(32,38);

					@media (max-width: $b2){
						@include font-size(28);
						@include line-height(28,39);
					}
				}
			}
		}
	}

	.inner{
		position: relative;
		display: flex;
		flex-wrap: wrap;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			&:hover,
			&:focus-visible{
				
				~ .img-con{
					border-radius: 0 0 90px 0;
				}
				
				~ .text-con{

					h5{
						color: $teal;
					}
				}
			}
		}
		
		.img-con{
			width: 31.7%;
			padding-top: 24.5%;
			border-radius: 0 0 0 0;
			transition: $time;

			@media (max-width: $b3){
				width: 100%;
				padding-top: 77.2%;
				margin-bottom: 0.65em;
			}
		}

		.text-con{
			width: 68.3%;
			position: relative;
			// padding-left: 139px;
			padding-left: 30px;

			@media (max-width: $b3){
				width: 100%;
				padding-left: 0;
			}

			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				// left: 139px;
				left: 30px;
				right: 0;
				height: 1px;
				background-color: rgba($blue-medium, .2);

				@media (max-width: $b3){
					display: none;
				}
			}

			.cats{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				@include font-size(12);
				letter-spacing: .05em;
				line-height: 1.8;
				text-transform: uppercase;
				padding-right: 150px;
				margin-bottom: 4.4em;

				@media (max-width: $b3){
					margin-bottom: .9em;
				}

				span{
					position: relative;
					display: inline-block;
					padding-left: 12px;
					margin-right: 12px;

					&:before{
						content: '';
						position: absolute;
						left: 0em;
						top: .6em;
						width: 7px;
						height: 7px;
						border-radius: 0 2px 0 2px;
						background-color: $blue-dark;
						transition: $time;
					}
				}
			}

			.date{
				align-items: center;
				@include font-size(12);
				line-height: 1.8;
				text-transform: uppercase;
				position: absolute;
				top: 1px;
				right: 0;
			}

			h5{
				width: 600px;
				transition: $time;

				@media (max-width: $b2){
					@include font-size(28);
					@include line-height(28,39);
				}
			}
		}
	}
}

.news-hero-section{
	padding-top: 299px;
	padding-bottom: 113px;

	@media (max-width: $b3){
		padding-top: 146px;
		padding-bottom: 37px;
	}

	.sub{
		margin-bottom: 2.2em;

		@media (max-width: $b3){
			margin-bottom: 1.4em;
		}

		span{

			&:after{
				content: ', ';
			}

			&:last-of-type{

				&:after{
					display: none;
				}
			}
		}
	}

	@media screen and (min-width: 1440px) {

		.waypoint.animate {
			
			.sub-con {
				transform: translateY(0);
			}

			.sub {
				transform: translateY(0);
			}
		}

		.sub-con {
			// overflow: hidden;
			transform: translateY(4rem);
			transition: 0.25s 0.4s !important;
		}

		.sub {
			margin-bottom: 2rem !important;
			transform: translateY(150%);
			transition: transform 0.25s 0.4s !important;

			.line {
				transition: max-width 0.5s 0.5s !important;
			}
		}

		.sub h1, .sub h2, .sub h3, .sub h4, .sub h5, .sub h6 {
			transition: all 0.25s;
		}
	}
}

.news-content-section{
	padding-bottom: 40px;

	@media (max-width: $b3){
		padding-bottom: 25px;
	}

	.col-12{

		> img{
			margin-bottom: 5.15em;

			@media (max-width: $b3){
				margin-left: -36px;
				width: calc(100% + 36px);
				max-width: calc(100% + 36px);
				min-height: 270px;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: 3.55em;
			}
		}

		.text-con{
			margin: 0 auto;
			width: 618px;
		}

		h5{
			margin-bottom: 1.6em;

			@media (max-width: $b3){
				margin-bottom: 1.4em;
			}
		}

		p{
			margin-bottom: 1.75em;

			@media (max-width: $b3){
				margin-bottom: 1.65em;
			}
		}
	}
}

.related-section{
	padding-top: 61px;
	padding-bottom: 107px;

	@media (max-width: $b3){
		position: relative;
		padding-bottom: 110px;
	}

	.row.top{
		align-items: flex-end;

		h5{
			margin-bottom: .8em;

			@media (max-width: $b2){
				@include font-size(34);
				@include line-height(34,48);
			}
		}

		.link-side{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 1em;
			margin-top: 0;

			@media (max-width: $b3){
				position: absolute;
				bottom: 76px;
				justify-content: flex-start;
			}
		}
	}

	.row.bottom{
		padding-top: 33px;
		justify-content: center;

		@media (max-width: 991px){
			
			.n-item:nth-child(3) {
				display: none;
			}
		}

		@media (max-width: 768px){
			
			.n-item:nth-child(3) {
				display: block;
			}
		}
	}
}

.location-section{
	padding-top: 37px;
	padding-bottom: 52px;

	@media (max-width: $b3){
		padding-bottom: 26px;
	}

	.sub{
		margin-bottom: 2.65em;

		@media (max-width: $b3){
			margin-bottom: 1.45em;
		}
	}

	h3{
		width: 900px;
	}

	.col-md-10{
		margin-bottom: 2.65em;

		@media (max-width: $b3){
			margin-bottom: 4.95em;
		}
	}

	.col-lg-3{
		margin-bottom: 1.6em;

		@media (max-width: $b3){
			margin-bottom: 3.5em;
		}

		&.waypoint{

			&.animate{

				.inner{

					&:before{
						max-width: 100%;
						transition: $time*2 $time;
					}
				}
			}
		}

		.inner{
			// padding-top: 1.9em;
			padding-top: .9em;
			position: relative;

			@media (max-width: $b3){
				padding-top: 1.5em;
			}

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 2px;
				width: 100%;
				max-width: 0;
				background-color: $teal;
			}

			h6{
				@include font-size(28);
				@include line-height(28,39);
				font-weight: 200;
				// margin-bottom: 1em;
				margin-bottom: 0.5em;

				@media (max-width: $b2){
					@include font-size(21);
					@include line-height(21,27);
				}
			}

			address{
				@include font-size(16);
				@include line-height(16,28);
				margin-bottom: 1.8em;

				@media (max-width: $b2){
					@include font-size(14);
					@include line-height(14,23);
					margin-bottom: 1.6em;
				}
			}

			span{
				@include font-size(16);
				@include line-height(16,28);

				@media (max-width: $b2){
					@include font-size(14);
					@include line-height(14,23);
				}
			}
		}
	}
}

.form-section{
	padding-top: 211px;
	padding-bottom: 144px;

	@media (max-width: $b3){
		padding-top: 150px;
		padding-bottom: 93px;
	}

	.container{
		max-width: 862px;
	}

	.sub{
		margin-bottom: 2.4em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}
	}

	.sub-con + .title-con-animated h2{
		margin-bottom: .95em;

		@media (max-width: $b3){
			margin-bottom: .85em;
		}
	}

	.top-con{
		margin-bottom: 1.8em;

		@media (max-width: $b3){
			margin-bottom: .3em;
		}
	}

	.acceptance-con{
		margin-top: -18px;

		@media (max-width: $b3){
			margin-top: -15px;
		}
	}

	.btn{

		@media (max-width: $b3){
			width: 100%;
			justify-content: center;
			padding: 18px 127px 18px 164px;

			svg{
				left: 129px;
			}

			&:hover,
			&:focus-visible{
				padding: 18px 164px 18px 127px;
				
				svg{
					left: calc(100% - 150px);
				}
			}
		}
	}
}

.search-hero-section{
	padding-top: 211px;
	padding-bottom: 124px;

	@media (max-width: $b3){
		padding-top: 150px;
		padding-bottom: 83px;
	}

	.sub{
		margin-bottom: 2.4em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}
	}

	h2{

		span{
			text-transform: uppercase;
		}
	}
}

.results-section{
	padding-bottom: 177px;

	@media (max-width: $b3){
		padding-bottom: 96px;
	}

	.top,
	.bottom{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.top{

	}

	.row{
		
	}

	.n-item{
 
		@media (max-width: 1200px){
			
			@media (min-width: $b3 + 1){
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}

	.results-side ~ .bottom{
		max-height: 0;
		overflow: hidden;
	}

	.arrow-link-con{
		width: 100%;
	}

	.title{
		margin-bottom: 2.1em;

		@media (max-width: $b3){
			margin-bottom: 1em;
		}

		h5{
			margin-bottom: .8em;

			@media (max-width: $b2){
				@include font-size(34);
				@include line-height(34,48);
			}
		}
	}

	.results-side{
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-bottom: 2.1em;

		@media (max-width: $b3){
			justify-content: flex-start;
			margin-bottom: 1em;
		}

		.results{
			@include font-size(16);
			@include line-height(16,28);
			letter-spacing: .04em;
			text-transform: uppercase;
			font-weight: 500;
			margin-bottom: .8em;

			@media (max-width: $b2){
				@include font-size(12);
				@include line-height(12,28);
			}
		}
	}

	.arrow-link-con{
		margin-top: -1.5em;
		position: relative;
		z-index: 1;

		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 15px;
			right: 15px;
			height: 1px;
			background-color: rgba($blue-dark, .3);
			transform: translateY(-50%);
			z-index: 1;

			@media (max-width: $b3){
				display: none;
			}
		}
	}

	.arrow-link{
		margin-top: 0;
		padding-right: 30px;
		background-color: white;
		z-index: 2;
		position: relative;
		display: inline-flex;
	}

	.row:not(:first-of-type){
		margin-top: 5em;

	}

	.n-item.n-item.n-item{
		margin-bottom: 2.431em;

		@media (max-width: $b2){
			
			h5{
				@include font-size(21);
				@include line-height(21,27);
			}
		}

		@media (max-width: $b3){
			
			.img-con{
				padding-top: 69.2%;
			}
		}
	}

	.services-row,
	.pa-row{

		.n-item{

			.img-con{
				margin-bottom: 1.35em;

				@media (max-width: $b3){
					margin-bottom: 0.65em;
				}
			}
		}
	}

	.row{

		&.show-more{

			.bottom{
				max-height: 9999px;
				transition: $time;
			}

			.arrow-link-con{
				display: none;
			}
		}
	}
}

.hero-with-text-section + .simple-text-section{
	padding-top: 0px;
}

.simple-text-section{
	padding-top: 150px;
	padding-bottom: 150px;

	@media (max-width: $b3){
		padding-top: 75px;
		padding-bottom: 75px;
	}
}


@media (max-width: 1554px){
	
	@media (min-width: $b2 + 1){

		.team-section .container,
		.services-section .container{
			max-width: calc(100% - 144px - 144px + 30px);
		}
		
	}
}

@media (max-width: 1462px){
	
	@media (min-width: $b2 + 1){

		.container{
			max-width: calc(100% - 99px - 99px + 30px);
		}
	}

	@media (max-width: $b2){
		
		.container,
		.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
			padding-right: 20px;
			padding-left: 36px;
		}

		.row{
			margin-right: -20px;
			margin-left: -36px;
		}
	}
}