//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-UltraLight.woff2') format('woff2'),
//		url('../fonts/NewHero-UltraLight.woff') format('woff'),
//		url('../fonts/NewHero-UltraLight.ttf') format('truetype');
//	font-weight: 200;
//	font-style: normal;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-Light.woff2') format('woff2'),
//		url('../fonts/NewHero-Light.woff') format('woff'),
//		url('../fonts/NewHero-Light.ttf') format('truetype');
//	font-weight: 300;
//	font-style: normal;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-Regular.woff2') format('woff2'),
//		url('../fonts/NewHero-Regular.woff') format('woff'),
//		url('../fonts/NewHero-Regular.ttf') format('truetype');
//	font-weight: 400;
//	font-style: normal;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-Medium.woff2') format('woff2'),
//		url('../fonts/NewHero-Medium.woff') format('woff'),
//		url('../fonts/NewHero-Medium.ttf') format('truetype');
//	font-weight: 500;
//	font-style: normal;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-SemiBold.woff2') format('woff2'),
//		url('../fonts/NewHero-SemiBold.woff') format('woff'),
//		url('../fonts/NewHero-SemiBold.ttf') format('truetype');
//	font-weight: 600;
//	font-style: normal;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'New Hero';
//	src: url('../fonts/NewHero-ExtraBold.woff2') format('woff2'),
//		url('../fonts/NewHero-ExtraBold.woff') format('woff'),
//		url('../fonts/NewHero-ExtraBold.ttf') format('truetype');
//	font-weight: 900;
//	font-style: normal;
//	font-display: swap;
//}

//BREAK POINTS
$b1:				1266px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#c30007;

$green:				#4CA67B;

$teal-light:		#B2EBED;
$teal:				#04B7BD;

$grey-border:		#707070;

$grey-lighter:		#F5F3F2;
$grey-light:		#D4D9DD;
$grey:				#5D5C5C;


$blue-lighter:		#293F4D;
$blue:				#22333D;
$blue-medium:		#2F4858;
$blue-dark:			#1D2C35;

$white:				#ffffff;
$black:				#000000;

$colour: 			$grey;
$main-bg-colour:	$white;

$font-new:			'new-hero', serif;
//ultra-light - 200
//light - 300
//regular - 400
//medium - 500
//semi - 600
//extra - 900

$main-font:			$font-new;
$alt-font:			$font-new;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	30px !default;

//!!PAGE WIDTH
$base-width:		1266px;

//!!SITE TRANSITIONS
$time:				.25s;