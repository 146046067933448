footer{
	position: relative;
	background-color: $blue-dark;

	*{
		color: rgba(white, .8);
	}

	.footer-top-outer{
		padding-top: 156px;
		padding-bottom: 105px;

		@media (max-width: $b3){
			padding-top: 79px;
			padding-bottom: 64px;
		}

		.logo-con{

			@media (max-width: $b3){
				order: 1;
				flex: 0 0 calc(100% - 126px);
				max-width: calc(100% - 126px);
			}
		}

		.menu-con{

			@media (max-width: $b2){
				
				@media (min-width: $b3 + 1){
					flex: 0 0 calc(75% - 126px);
					max-width: calc(75% - 126px);
				}
			}

			@media (max-width: $b3){
				order: 3;
			}
		}

		.back-con{

			@media (max-width: $b2){
				flex: 0 0 126px;
				max-width: 126px;
			}

			@media (max-width: $b3){
				order: 2;
				flex: 0 0 126px;
				max-width: 126px;
				padding-top: 69px;
			}

			a{
				border-top: 2px solid rgba(white, .5);
				width: 78px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 95px;

				@media (max-width: $b3){
					height: 55px;
				}

				&:hover,
				&:focus-visible{
					
					svg{

						*{
							fill: $teal;
						}
					}
				}
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			max-width: 100%;
			margin: 0 0 3.4em;
			transition: $time;
			z-index: 1;
			width: 204px;
			z-index: 50;
			transition: 0s;

			@media (max-width: $b2){
				width: 159px;
				margin: 0 0 2.9em;
			}

			#logoText{
				fill: white;
			}

			svg,
			img{
				display: block;
				width: 100%;
			}
		}

		.social-menu{

			a{

				&:hover,
				&:focus-visible{
					background-color: $teal;
					color: $white!important;

					*{
						color: $white!important;
					}
				}
			}
		}

		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@include font-size(16);
			line-height: 1;
			justify-content: space-between;

			@media (max-width: $b3){
				@include font-size(13);
				margin-top: 63px;
			}

			li{
				@include font-size(16);
				line-height: 1.5;
				margin-bottom: 14px;
				width: calc(50% - 15px);

				@media (max-width: $b3){
					@include font-size(13);
					margin-bottom: 14.5px;
					width: calc(50% - 8px);
				}

				&:first-of-type{
					padding-left: 0;
				}
				
				a{
					@include font-size(16);
					line-height: 1.5;
					font-weight: 400;
					text-decoration: none;
					color: rgba($white, .8);
					display: block;

					@media (max-width: $b3){
						@include font-size(13);
					}

					&:hover,
					&:focus-visible{
						color: $teal!important;
					}
				}
			}
		}

		.social-menu{

			a{
				color: $blue-dark;

				*{
					color: $blue-dark;
				}
			}

			// @media (max-width: $b1){
			// 	justify-content: center;

			// 	a{
			// 		@include font-size(42);
			// 		line-height: 42px;
			// 		min-width: 42px;
			// 		margin: 0 18px;

			// 		*{
			// 			@include font-size(42);
			// 			line-height: 42px;
			// 			min-width: 42px;
			// 		}
			// 	}
			// }
		}
	}

	.footer-bottom-outer{
		padding: 35px 0 75px;
		width: 100%;
		border-top: 1px solid rgba(white, .5);

		@media (max-width: $b2){
			padding: 16px 0 26px;
		}

		.copy-con{
			
			@media (max-width: $b3){
				padding-right: 2px;
			}
		}

		.copy{
			@include font-size(14);
			@include line-height(14,28);
			color: rgba(white, .7);
			font-weight: 400;

			@media (max-width: $b3){
				@include font-size(12);
			}
		}

		.small-con{
			
			@media (max-width: $b3){
				padding-left: 2px;
			}
		}

		.small-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@include font-size(14);
			@include line-height(14,28);
			justify-content: flex-end;

			@media (max-width: $b3){
				@include font-size(12);
			}

			li{
				@include font-size(14);
				@include line-height(14,28);

				@media (max-width: $b3){
					@include font-size(12);
				}

				+ li{
					margin-left: 50px;

					@media (max-width: $b3){
						margin-left: 18px;
					}
				}
				
				a{
					@include font-size(14);
					@include line-height(14,28);
					font-weight: 400;
					text-decoration: none;
					color: rgba($white, .7);
					display: block;

					@media (max-width: $b3){
						@include font-size(12);
					}

					&:hover,
					&:focus-visible{
						color: $teal!important;
					}
				}
			}
		}
	}
}