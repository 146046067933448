//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -20px;
	max-width: calc(100% + 20px + 20px);
	flex-wrap: wrap;

	.btn{
		margin: 0 20px 12px;

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}

		@media (min-width: $b3 + 1){
			
			&.big:first-of-type:not(:last-of-type){
				margin-left: -8px;
			}
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	padding: 24.4049px 27px 24.4049px 67px;
	font-size: 1.6rem!important;
	letter-spacing: .05em;
	text-decoration: none!important;
	line-height: 1.2em;
	border: 1px solid $teal;
	color: $white!important;
	font-weight: 600;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $main-font;
	position: relative;
	border-radius: 0;
	transition: $time;
	background-color: $teal;
	text-transform: uppercase;

	@media (max-width: $b3){
		padding: 18px 27px 18px 64px;
		font-size: 1.4rem!important;
	}

	svg{
		position: absolute;
		top: 50%;
		left: 27px;
		width: 20px;
		height: 11.997px;
		transform: translateY(-50%);

		*{
			fill: white!important;
		}
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		padding: 24.4049px 67px 24.4049px 27px;
		background-color: $teal-light;
		border-color: $teal-light;
		color: #2F4858!important;

		@media (max-width: $b3){
			padding: 18px 64px 18px 27px;
		}

		svg{
			left: calc(100% - 47px);

			*{
				fill: #2F4858!important;
			}
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{
	will-change: transform;

	&.transparent{
		background-color: transparent;
		border-color: $teal;
		color: $teal!important;

		svg{

			*{
				fill: $teal!important;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $teal-light;
			border-color: $teal-light;
			color: #2F4858!important;

			svg{

				*{
					fill: #2F4858!important;
				}
			}
		}
	}

	&.big{
		padding: 30px 31px;
		border-radius: 21px;
		line-height: 1.2;
	}

	&.small{
		padding: 6px 10px;
		font-size: 11px!important;
		font-size: 1.1rem!important;
	}
}

