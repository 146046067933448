//!!FORM
::-webkit-input-placeholder {
	@include font-size(21);
	color: $grey;
	font-weight: 300;

	@media (max-width: $b3){
		@include font-size(14);
	}
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(21);
	color: $grey;
	font-weight: 300;

	@media (max-width: $b3){
		@include font-size(14);
	}
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(21);
	color: $grey;
	font-weight: 300;

	@media (max-width: $b3){
		@include font-size(14);
	}
}

:-ms-input-placeholder {
	@include font-size(21);
	color: $grey;
	font-weight: 300;

	@media (max-width: $b3){
		@include font-size(14);
	}
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 48px;
$label-bottom-margin: -2px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 16px 0px;
	@include font-size(21);
	font-weight: 300;
	color: $grey;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 1px solid rgba($blue-dark, .3);
	border-top: none;
	border-right: none;
	border-left: none;
	background-color: $white;
	border-radius: 0px;
	line-height: 1.3em;
	-webkit-appearance: none;
	box-shadow: none;

	@media (max-width: $b3){
		padding: 10px 0px;
		@include font-size(16);
		margin-bottom: 36px;
	}
}

textarea{
	height: 194px;
	min-height: 194px;
	min-width: 100%;

	@media (max-width: $b3){
		height: 126px;
		min-height: 126px;
	}
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 45px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 59.3px;
	height: 60.3px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	@media (max-width: $b3){
		line-height: 38.2px;
		height: 39.2px;
	}

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(16);
	@include line-height(16,28);
	letter-spacing: .04em;
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 500;
	color: $blue-dark;

	@media (max-width: $b3){
		@include font-size(14);
		@include line-height(14,28);
	}

	span{
		color: $teal;
	}

	.gfield_required{
		
		&:after{
			content: '*';
			color: $teal;
		}
		
		span{
			display: none;
		}
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
	width: auto;
	margin-top: 29px;

	@media (max-width: $b3){
		margin-top: -30px;
	}
}

.submit-con{
	display: flex;
	justify-content: center;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 47px!important;
		position: relative;
		cursor: pointer;
		@include font-size(21);
		line-height: 29px;
		@include inline;
		margin-bottom: 5px;
		font-style: normal;
		color: $colour;
		font-weight: 300;
		text-transform: none;
		text-align: left!important;
		font-family: $main-font;
		letter-spacing: 0;

		@media (max-width: $b3){
			@include font-size(14);
			line-height: 22px;
			padding-left: 32px!important;
		}

		a{
			color: $teal;

			&:hover,
			&:focus-visible{
				color: $teal-light;
			}
		}

		&:before{
			position: absolute;
			content: "";
			width: 29px;
			height: 29px;
			left: 0px;
			top: 0px;
			border-radius: 0;
			background-color: $white;
			border: 1px solid rgba($blue-dark, .3);

			@media (max-width: $b3){
				width: 22px;
				height: 22px;
			}

		}

		&:after{
			position: absolute;
			content: "";
			width: 29px;
			height: 29px;
			left: 0px;
			top: 0px;
			border-radius: 0;
			background-color: $teal;
			background-image: url(../images/tick.svg);
			background-repeat: no-repeat;
			opacity: 0;
			transition: $time;
			border: 1px solid rgba($blue-dark, .3);
			background-position: 50% 50%;

			@media (max-width: $b3){
				width: 22px;
				height: 22px;
				background-size: 17px auto;
			}
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 5px;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!CONTACT FORM 7 START
//
.send-con{
	display: flex;
	justify-content: flex-end;
	padding: 3px 0 0;
}

.wpcf7-form.invalid{

	*[type="submit"], 
	.submit-con [type="submit"]{
		order: 9999;
	}
}

.wpcf7{
	position: relative;

	.col{
		position: static;
	}

	h3{
		width: 100%;
	}

	.your-consent{
		padding-top: 10px;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		.field-p{
			margin-bottom: 0;
		}

		&.sent{

			.wpcf7-list-item{

				label{
					opacity: 1;
				}
			}

			label,
			.wpcf7-form-control-wrap,
			input[type="submit"],
			button,
			.num-button{
				pointer-events: none;
				opacity: .2;
				transition: .4s;
			}
		}

		label{

			span{
				color: $red;
			}
		}

		.wpcf7-form-control-wrap{
			display: block;
			width: 100%;
		}

		> div{
			width: 100%;
		}

		.form-text{
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.small,
		.medium,
		.large{
			position: relative;
		}

		.small{
			width: 32%;
			width: calc(33.333% - (#{$grid-gutter-width}/2));
			width: calc(33.333% - 5px);

			@media (max-width: $b3){
				width: calc(50% - (#{$grid-gutter-width}/2));
				width: calc(50% - 5px);

				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.medium{
			width: calc(50% - (#{$grid-gutter-width}/2));
			width: calc(50% - 5px);

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.large{
			width: 100%;
		}

		.has-label{
			display: flex;
			align-items: baseline;

			label{
				width: 100px;
			}

			.wpcf7-form-control-wrap{
				width: calc(100% - 100px);
			}
		}

		.btn-con{
			position: static;
			justify-content: center;
		}

		span.wpcf7-not-valid-tip{
			position: absolute;
			background-color: transparent;
			color: $red!important;
			@include font-size(12);
			font-weight: 400;
			line-height: 14px;
			width: auto;
			bottom: -14px;
			transition: $time;
			left: 0px;
			display: none;

			@media (max-width: $b1){
				@include font-size(10);
				bottom: -12px;
			}
		}

		.wpcf7-acceptance,
		.wpcf7-checkbox,
		.wpcf7-radio{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: $input-bottom-margin;

			label{
				margin-bottom: $label-bottom-margin;
			}

			.wpcf7-list-item-label{
				margin-right: 32px;
				margin-right: $grid-gutter-width;
			}
		}

		.wpcf7-list-item{
			display: block;
			margin: 0;

			.wpcf7-list-item-label{
				margin-bottom: 0;
			}

			&:last-of-type{

				label{
					margin-bottom: 0;
				}
			}

			label{
				margin-bottom: $input-bottom-margin/2;
				text-align: left;
			}
		}
	}

	.wpcf7-spinner{
		background-color: $red;
		display: none!important;
	}

	.wpcf7-form.submitting{
		position: relative;

		[type="submit"],
		.wpcf7-form-control-wrap{
			pointer-events: none;
			opacity: .2;
		}

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			width: 20px;
			height: 20px;
			animation-name: loading-rotate;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $teal;
			border-radius: 0 10px 10px 10px;
			border-width: 1px;
			z-index: 1;

			@keyframes loading-rotate{
				0%,
				100%{
					transform: translate(-50%, -50%) rotate(0deg);
					border-width: 1px;
				}

				99.999%{
					transform: translate(-50%, -50%) rotate(359.999deg);
					border-width: 10px;
				}
			}
		}
	}

	div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
	div.wpcf7-mail-sent-ok,
	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing{
		margin: 1.4em 0 0;
		border: 1px solid $red;
		background-color: rgba(white, .15);
		color: $red;
		text-align: center;
		@include font-size(16);
		line-height: 1.4em;
		padding: 10px 5px;
		border-radius: 0px;
		font-weight: 400;

		@media (max-width: $b2){
			@include font-size(14);
		}
	}

	.sent{

		div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output{
			color: $green;
			border-color: $green;
		}
	}

	div.wpcf7-mail-sent-ok{
		background-color: $green;
		border-color: $green;
	}

	.LV_validation_message{
		display: none;
	}
}

//
//!!CONTACT FORM 7 END
//